import { useEffect, useState } from 'react';

import useLocalStorage from './useLocalStorage';

const useTheme = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [channelId] = useLocalStorage('channelId', '');
  const [currentTheme, setCurrentTheme] = useState('');

  const setToLowerCaseTheme = (theme: string) => {
    setCurrentTheme(theme.toLowerCase());
  };

  const removeDefaultTheme = () => {
    const element = window.document.body;
    element.classList.remove('bond');
  };

  const switchTheme = (theme: string) => {
    setToLowerCaseTheme(theme);
  };

  if (channelId) {
    removeDefaultTheme();
  }

  useEffect(() => {
    setToLowerCaseTheme(channelId);
  }, []);

  return {
    currentTheme: currentTheme ? currentTheme.split('-')[0] : '',
    switchTheme,
  };
};

export default useTheme;
