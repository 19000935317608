import { Auth } from 'aws-amplify';
import { createContext, useContext, useEffect, useState } from 'react';

import { PermissionRole } from 'Permissions';
import { TOKEN_DISABLED } from 'utils/configs';

interface UserRoleContextInterface {
  userRole: PermissionRole;
}

interface UserRoleProviderProps {
  children: React.ReactNode;
}

const UserRoleContext = createContext({} as UserRoleContextInterface);

UserRoleContext.displayName = 'UserRoleContext';

// To provide userRole from token (custom:role)
// possible values: EMP_ONBOARDING_READ_ONLY, EMPLOYEE, WORKFLOW_ADMIN, EMPLOYER (PermissionRole)

export const UserRoleProvider = ({ children }: UserRoleProviderProps) => {
  const [userRole, setUserRole] = useState<PermissionRole>();

  useEffect(() => {
    const retrieveCustomRole = async () => {
      if (!TOKEN_DISABLED) {
        const data = await Auth.currentSession();
        const token = data.getIdToken();
        setUserRole(JSON.parse(JSON.stringify(token.payload['custom:role'])));
      }
    };
    retrieveCustomRole();
  }, [userRole]);

  if (!userRole) {
    return <div>Loading...</div>;
  }
  return (
    <UserRoleContext.Provider value={{ userRole }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRoleContext = () => {
  const context = useContext(UserRoleContext);
  if (context === undefined) {
    throw new Error(
      'useUserRoleContext must be used within a UserRoleProvider'
    );
  }
  return context;
};
