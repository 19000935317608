import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { lazy, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';

import { ConfigProvider } from 'contexts/configContext';
import { UserRoleProvider } from 'contexts/userRoleContext';
import GeneralError from 'pages/GeneralError/GeneralError';
import { Role } from 'types/user';

import Loading from './components/Loading/Loading';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { HierarchyProvider } from './contexts/hierarchyContext';
import useUserRoles from './hooks/useUserRoles';
import { queryClient } from './lib/react-query';
import Theme from './Theme';
import { USER_ROLES } from './utils/configs';

const AdminRoutes = lazy(() => import('./AdminRoutes'));
const UserRoutes = lazy(() => import('./UserRoutes'));

const App = () => {
  const userRoles = useUserRoles();

  const isAdmin =
    userRoles.includes(USER_ROLES.ADMIN as Role) ||
    userRoles.includes(USER_ROLES.ROLE_EMP_ONBOARDING_READ_ONLY as Role) ||
    userRoles.includes(USER_ROLES.WORKFLOW_ADMIN as Role);

  const onReset = () => {
    window.location.replace('/');
  };

  const myErrorHandler = (error: Error, info: { componentStack: string }) => {
    console.log(error, info);
  };

  return userRoles.length > 0 ? (
    <ErrorBoundary
      FallbackComponent={GeneralError}
      onError={myErrorHandler}
      onReset={onReset}
    >
      <QueryClientProvider client={queryClient}>
        <UserRoleProvider>
          <HierarchyProvider useRoles={userRoles}>
            <ConfigProvider>
              <Theme />
              <div className="app">
                <Router>
                  <ScrollToTop />
                  <ReactQueryDevtools initialIsOpen={false} />
                  <Suspense
                    fallback={
                      <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
                        <Loading />
                      </div>
                    }
                  >
                    {isAdmin ? (
                      <AdminRoutes userRoles={userRoles} />
                    ) : (
                      <UserRoutes />
                    )}
                  </Suspense>
                </Router>
                <ToastContainer
                  position="top-center"
                  transition={Slide}
                  autoClose={false}
                  hideProgressBar
                  pauseOnFocusLoss
                  newestOnTop={false}
                  closeOnClick
                />
              </div>
            </ConfigProvider>
          </HierarchyProvider>
        </UserRoleProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  ) : null;
};

export default App;
