import {
  TimesheetRequestModelContractType,
  WorkerSearchRequestStatusItem,
} from 'types/api';

const {
  REACT_APP_AUTH_DISABLED,
  REACT_APP_API_HOST,
  REACT_APP_AWS_REGION,
  REACT_APP_AWS_AUTH_USER_POOL_ID,
  REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENTID,
} = process.env;

export const TOKEN_DISABLED = REACT_APP_AUTH_DISABLED === 'true';

export const API_HOST = REACT_APP_API_HOST;

export const AUTH_CONFIG = {
  region: REACT_APP_AWS_REGION,
  userPoolId: REACT_APP_AWS_AUTH_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_AWS_AUTH_USER_POOL_WEB_CLIENTID,
};

export const USER_ROLES = {
  ADMIN: 'ROLE_ADMIN',
  WORKFLOW_ADMIN: 'ROLE_WORKFLOW_ADMIN',
  ROLE_EMP_ONBOARDING_READ_ONLY: 'ROLE_EMP_ONBOARDING_READ_ONLY',
  CANDIDATE: 'ROLE_EMPLOYEE',
};

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;

export const ALLOWED_PAGE_SIZES = [10, 20, 50, 100];

// Person statuses: IN_PROGRESS, PENDING_APPROVAL, COMPLETED, NO_FLOW, ACTIVE
// Onboarding flow statuses: NOT_READY, IN_PROGRESS, COMPLETED
// Task statuses: NOT_READY, READY_TO_START, PENDING, COMPLETED, OPTIONAL, REJECTED
// Document statuses: SUBMITTED, VALID, REJECTED, ERROR, INVALID

export const NOT_READY = 'NOT_READY';
export const READY_TO_START = 'READY_TO_START';
export const IN_PROGRESS = 'IN_PROGRESS';
export const PENDING = 'PENDING';
export const PENDING_APPROVAL = 'PENDING_APPROVAL';
export const COMPLETED = 'COMPLETED';
export const ACTIVATED = 'ACTIVATED';
export const OPTIONAL = 'OPTIONAL';
export const REJECTED = 'REJECTED';
export const NO_FLOW = 'NO_FLOW';
export const SUBMITTED = 'SUBMITTED';
export const VALID = 'VALID';
export const ERROR = 'ERROR';
export const INVALID = 'INVALID';
export const DEACTIVATED = 'DEACTIVATED';
export const NEW = 'NEW';
export const VERIFYING = 'VERIFYING';
export const ACTIVE = 'ACTIVE';
export const ARCHIVED = 'ARCHIVED';
export const EXPIRED = 'EXPIRED';
export const FUTURE = 'FUTURE';

export const PERSON_STATUS = {
  IN_PROGRESS,
  PENDING_APPROVAL,
  COMPLETED,
  NO_FLOW,
  ACTIVATED,
  DEACTIVATED,
  EXPIRED,
  FUTURE,
};

export const FLOW_STATUS = {
  NOT_READY,
  IN_PROGRESS,
  COMPLETED,
};

export const TASK_STATUS = {
  NOT_READY,
  READY_TO_START,
  PENDING,
  COMPLETED,
  OPTIONAL,
  REJECTED,
};

//this array set to use with available conditional info box display for doc upload task (DocumentStatusInfo)
export const TASK_STATUS_DISPLAY_SET = [
  TASK_STATUS.PENDING,
  TASK_STATUS.REJECTED,
  TASK_STATUS.COMPLETED,
];

export const DOCUMENT_STATUS = {
  SUBMITTED,
  VALID,
  REJECTED,
  ERROR,
  INVALID,
};

export const CANDIDATE_STATUS = {
  NEW,
  VERIFYING,
  ACTIVATED,
  ARCHIVED,
};

export const WORK_STATUSES = {
  NEW: WorkerSearchRequestStatusItem.NO_FLOW,
  VERIFYING: `${WorkerSearchRequestStatusItem.IN_PROGRESS},${WorkerSearchRequestStatusItem.PENDING_APPROVAL},${WorkerSearchRequestStatusItem.COMPLETED}`,
  ACTIVE: WorkerSearchRequestStatusItem.ACTIVATED,
  ARCHIVED: WorkerSearchRequestStatusItem.DEACTIVATED,
};

export const NEW_WORKER_PAGES = {
  WORKER_DETAILS: 'Worker Details',
  ENGAGEMENT_TYPE: 'Engagement Type',
  CONTRACT_DETAILS: 'Contract Details',
  RATES: 'Rates',
  WORKSITE_DETAILS: 'Worksite Details',
  TIMESHEET: 'Timesheet',
  WORK_ORDER: 'Work Order',
  ADDITIONAL_ATTACHMENTS: 'Additional Attachments',
};

export const ENGAGEMENT_TYPES = [
  {
    value: 'ABN',
    label: 'ABN',
  },
  {
    value: 'PAYG',
    label: 'PAYG',
  },
];

export const TIMESHEET_SYSTEMS = [
  {
    value: 'CONSORTIOS_TIMESHEET_SYSTEM',
    label: "Consortio's timesheet system",
  },
  {
    value: 'MY_TIMESHEET_SYSTEM',
    label: 'My timesheet system',
  },
];

export const WORKING_HOURS_UNITS = [
  { value: 'hrs/day', label: 'hours per day' },
  { value: 'hrs/week', label: 'hours per week' },
];

export const SCAFFIDI_WORKING_HOURS_UNITS = [
  { value: 'hrs/day', label: 'hours per day' },
  { value: 'hrs/week', label: 'hours per week' },
  { value: 'hrs/fortnight', label: 'hours per fortnight' },
];

export const NOTICE_PERIOD_UNITS = [
  { value: 'weeks', label: 'weeks' },
  { value: 'days', label: 'days' },
];

export const TYPES_OF_RATE = [
  { value: 'DAILY', label: 'daily' },
  { value: 'HOURLY', label: 'hourly' },
];

export const CASUAL_LOADING_RATE = 0.25;

export const AUSTRALIAN_STATES = [
  {
    value: 'ACT',
    label: 'Australian Capital Territory',
  },
  {
    value: 'NSW',
    label: 'New South Wales',
  },
  {
    value: 'NT',
    label: 'Northern Territory',
  },
  {
    value: 'QLD',
    label: 'Queensland',
  },
  {
    value: 'SA',
    label: 'South Australia',
  },
  {
    value: 'TAS',
    label: 'Tasmania',
  },
  {
    value: 'VIC',
    label: 'Victoria',
  },
  {
    value: 'WA',
    label: 'Western Australia',
  },
];

export const BASIS_OF_PAYMENT_CODES = [
  { value: 'F', label: 'Full-time employment' },
  { value: 'P', label: 'Part-time employment' },
  { value: 'L', label: 'Labour hire' },
  { value: 'S', label: 'Superannuation or annuity income stream' },
  { value: 'C', label: 'Casual employment' },
];

export const PERSON_DEMOGRAPHIC_DETAILS_SEX_CODES = [
  {
    value: 'F',
    label: 'Female',
  },
  {
    value: 'M',
    label: 'Male',
  },
  {
    value: 'I',
    label: 'Intersex or Indeterminate',
  },
  {
    value: 'N',
    label: 'I choose not to disclose',
  },
];

// SMSF Alias Lookup version 21 [October 2021] (https://softwaredevelopers.ato.gov.au/supervalidationservices#smsf-alias-lookup-table)
export const ESA_ALIASES = [
  'AUSPOSTSMSF',
  'CFSCSSMSF',
  'ClearviewSMSF',
  'CLICKSUPER',
  'ESUPERFUND',
  'MACQUARIESMSF',
  'MercerSMSF',
  'MySMSFMailbox',
  'netwealthsmsf',
  'QUICKSUPER',
  'smsfdataflow',
  'SUPERCHOICE',
  'SuperMate',
  'TheSMSFManagers',
  'BGLSF360',
  'wrkrSMSF',
];

export const COUNTRY_ISO3_CODES = [
  { label: 'Staging test country', value: 'CWR' },
  { label: 'Afghanistan', value: 'AFG' },
  { label: 'Åland Islands', value: 'ALA' },
  { label: 'Albania', value: 'ALB' },
  { label: 'Algeria', value: 'DZA' },
  { label: 'American Samoa', value: 'ASM' },
  { label: 'Andorra', value: 'AND' },
  { label: 'Angola', value: 'AGO' },
  { label: 'Anguilla', value: 'AIA' },
  { label: 'Antarctica', value: 'ATA' },
  { label: 'Antigua and Barbuda', value: 'ATG' },
  { label: 'Argentina', value: 'ARG' },
  { label: 'Armenia', value: 'ARM' },
  { label: 'Aruba', value: 'ABW' },
  { label: 'Australia', value: 'AUS' },
  { label: 'Austria', value: 'AUT' },
  { label: 'Azerbaijan', value: 'AZE' },
  { label: 'Bahamas', value: 'BHS' },
  { label: 'Bahrain', value: 'BHR' },
  { label: 'Bangladesh', value: 'BGD' },
  { label: 'Barbados', value: 'BRB' },
  { label: 'Belarus', value: 'BLR' },
  { label: 'Belgium', value: 'BEL' },
  { label: 'Belize', value: 'BLZ' },
  { label: 'Benin', value: 'BEN' },
  { label: 'Bermuda', value: 'BMU' },
  { label: 'Bhutan', value: 'BTN' },
  { label: 'Bolivia (Plurinational State of)', value: 'BOL' },
  { label: 'Bonaire, Sint Eustatius and Saba', value: 'BES' },
  { label: 'Bosnia and Herzegovina', value: 'BIH' },
  { label: 'Botswana', value: 'BWA' },
  { label: 'Bouvet Island', value: 'BVT' },
  { label: 'Brazil', value: 'BRA' },
  { label: 'British Indian Ocean Territory', value: 'IOT' },
  { label: 'Brunei Darussalam', value: 'BRN' },
  { label: 'Bulgaria', value: 'BGR' },
  { label: 'Burkina Faso', value: 'BFA' },
  { label: 'Burundi', value: 'BDI' },
  { label: 'Cabo Verde', value: 'CPV' },
  { label: 'Cambodia', value: 'KHM' },
  { label: 'Cameroon', value: 'CMR' },
  { label: 'Canada', value: 'CAN' },
  { label: 'Cayman Islands', value: 'CYM' },
  { label: 'Central African Republic', value: 'CAF' },
  { label: 'Chad', value: 'TCD' },
  { label: 'Chile', value: 'CHL' },
  { label: 'China', value: 'CHN' },
  { label: 'Christmas Island', value: 'CXR' },
  { label: 'Cocos (Keeling) Islands', value: 'CCK' },
  { label: 'Colombia', value: 'COL' },
  { label: 'Comoros', value: 'COM' },
  { label: 'Congo', value: 'COG' },
  { label: 'Congo, Democratic Republic of the', value: 'COD' },
  { label: 'Cook Islands', value: 'COK' },
  { label: 'Costa Rica', value: 'CRI' },
  { label: "Côte d'Ivoire", value: 'CIV' },
  { label: 'Croatia', value: 'HRV' },
  { label: 'Cuba', value: 'CUB' },
  { label: 'Curaçao', value: 'CUW' },
  { label: 'Cyprus', value: 'CYP' },
  { label: 'Czechia', value: 'CZE' },
  { label: 'Denmark', value: 'DNK' },
  { label: 'Djibouti', value: 'DJI' },
  { label: 'Dominica', value: 'DMA' },
  { label: 'Dominican Republic', value: 'DOM' },
  { label: 'Ecuador', value: 'ECU' },
  { label: 'Egypt', value: 'EGY' },
  { label: 'El Salvador', value: 'SLV' },
  { label: 'Equatorial Guinea', value: 'GNQ' },
  { label: 'Eritrea', value: 'ERI' },
  { label: 'Estonia', value: 'EST' },
  { label: 'Eswatini', value: 'SWZ' },
  { label: 'Ethiopia', value: 'ETH' },
  { label: 'Falkland Islands (Malvinas)', value: 'FLK' },
  { label: 'Faroe Islands', value: 'FRO' },
  { label: 'Fiji', value: 'FJI' },
  { label: 'Finland', value: 'FIN' },
  { label: 'France', value: 'FRA' },
  { label: 'French Guiana', value: 'GUF' },
  { label: 'French Polynesia', value: 'PYF' },
  { label: 'French Southern Territories', value: 'ATF' },
  { label: 'Gabon', value: 'GAB' },
  { label: 'Gambia', value: 'GMB' },
  { label: 'Georgia', value: 'GEO' },
  { label: 'Germany', value: 'DEU' },
  { label: 'Ghana', value: 'GHA' },
  { label: 'Gibraltar', value: 'GIB' },
  { label: 'Greece', value: 'GRC' },
  { label: 'Greenland', value: 'GRL' },
  { label: 'Grenada', value: 'GRD' },
  { label: 'Guadeloupe', value: 'GLP' },
  { label: 'Guam', value: 'GUM' },
  { label: 'Guatemala', value: 'GTM' },
  { label: 'Guernsey', value: 'GGY' },
  { label: 'Guinea', value: 'GIN' },
  { label: 'Guinea-Bissau', value: 'GNB' },
  { label: 'Guyana', value: 'GUY' },
  { label: 'Haiti', value: 'HTI' },
  { label: 'Heard Island and McDonald Islands', value: 'HMD' },
  { label: 'Holy See', value: 'VAT' },
  { label: 'Honduras', value: 'HND' },
  { label: 'Hong Kong', value: 'HKG' },
  { label: 'Hungary', value: 'HUN' },
  { label: 'Iceland', value: 'ISL' },
  { label: 'India', value: 'IND' },
  { label: 'Indonesia', value: 'IDN' },
  { label: 'Iran (Islamic Republic of)', value: 'IRN' },
  { label: 'Iraq', value: 'IRQ' },
  { label: 'Ireland', value: 'IRL' },
  { label: 'Isle of Man', value: 'IMN' },
  { label: 'Israel', value: 'ISR' },
  { label: 'Italy', value: 'ITA' },
  { label: 'Jamaica', value: 'JAM' },
  { label: 'Japan', value: 'JPN' },
  { label: 'Jersey', value: 'JEY' },
  { label: 'Jordan', value: 'JOR' },
  { label: 'Kazakhstan', value: 'KAZ' },
  { label: 'Kenya', value: 'KEN' },
  { label: 'Kiribati', value: 'KIR' },
  { label: "Korea (Democratic People's Republic of)", value: 'PRK' },
  { label: 'Korea, Republic of', value: 'KOR' },
  { label: 'Kuwait', value: 'KWT' },
  { label: 'Kyrgyzstan', value: 'KGZ' },
  { label: "Lao People's Democratic Republic", value: 'LAO' },
  { label: 'Latvia', value: 'LVA' },
  { label: 'Lebanon', value: 'LBN' },
  { label: 'Lesotho', value: 'LSO' },
  { label: 'Liberia', value: 'LBR' },
  { label: 'Libya', value: 'LBY' },
  { label: 'Liechtenstein', value: 'LIE' },
  { label: 'Lithuania', value: 'LTU' },
  { label: 'Luxembourg', value: 'LUX' },
  { label: 'Macao', value: 'MAC' },
  { label: 'Madagascar', value: 'MDG' },
  { label: 'Malawi', value: 'MWI' },
  { label: 'Malaysia', value: 'MYS' },
  { label: 'Maldives', value: 'MDV' },
  { label: 'Mali', value: 'MLI' },
  { label: 'Malta', value: 'MLT' },
  { label: 'Marshall Islands', value: 'MHL' },
  { label: 'Martinique', value: 'MTQ' },
  { label: 'Mauritania', value: 'MRT' },
  { label: 'Mauritius', value: 'MUS' },
  { label: 'Mayotte', value: 'MYT' },
  { label: 'Mexico', value: 'MEX' },
  { label: 'Micronesia (Federated States of)', value: 'FSM' },
  { label: 'Moldova, Republic of', value: 'MDA' },
  { label: 'Monaco', value: 'MCO' },
  { label: 'Mongolia', value: 'MNG' },
  { label: 'Montenegro', value: 'MNE' },
  { label: 'Montserrat', value: 'MSR' },
  { label: 'Morocco', value: 'MAR' },
  { label: 'Mozambique', value: 'MOZ' },
  { label: 'Myanmar', value: 'MMR' },
  { label: 'Namibia', value: 'NAM' },
  { label: 'Nauru', value: 'NRU' },
  { label: 'Nepal', value: 'NPL' },
  { label: 'Netherlands', value: 'NLD' },
  { label: 'New Caledonia', value: 'NCL' },
  { label: 'New Zealand', value: 'NZL' },
  { label: 'Nicaragua', value: 'NIC' },
  { label: 'Niger', value: 'NER' },
  { label: 'Nigeria', value: 'NGA' },
  { label: 'Niue', value: 'NIU' },
  { label: 'Norfolk Island', value: 'NFK' },
  { label: 'North Macedonia', value: 'MKD' },
  { label: 'Northern Mariana Islands', value: 'MNP' },
  { label: 'Norway', value: 'NOR' },
  { label: 'Oman', value: 'OMN' },
  { label: 'Pakistan', value: 'PAK' },
  { label: 'Palau', value: 'PLW' },
  { label: 'Palestine, State of', value: 'PSE' },
  { label: 'Panama', value: 'PAN' },
  { label: 'Papua New Guinea', value: 'PNG' },
  { label: 'Paraguay', value: 'PRY' },
  { label: 'Peru', value: 'PER' },
  { label: 'Philippines', value: 'PHL' },
  { label: 'Pitcairn', value: 'PCN' },
  { label: 'Poland', value: 'POL' },
  { label: 'Portugal', value: 'PRT' },
  { label: 'Puerto Rico', value: 'PRI' },
  { label: 'Qatar', value: 'QAT' },
  { label: 'Réunion', value: 'REU' },
  { label: 'Romania', value: 'ROU' },
  { label: 'Russian Federation', value: 'RUS' },
  { label: 'Rwanda', value: 'RWA' },
  { label: 'Saint Barthélemy', value: 'BLM' },
  { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SHN' },
  { label: 'Saint Kitts and Nevis', value: 'KNA' },
  { label: 'Saint Lucia', value: 'LCA' },
  { label: 'Saint Martin (French part)', value: 'MAF' },
  { label: 'Saint Pierre and Miquelon', value: 'SPM' },
  { label: 'Saint Vincent and the Grenadines', value: 'VCT' },
  { label: 'Samoa', value: 'WSM' },
  { label: 'San Marino', value: 'SMR' },
  { label: 'Sao Tome and Principe', value: 'STP' },
  { label: 'Saudi Arabia', value: 'SAU' },
  { label: 'Senegal', value: 'SEN' },
  { label: 'Serbia', value: 'SRB' },
  { label: 'Seychelles', value: 'SYC' },
  { label: 'Sierra Leone', value: 'SLE' },
  { label: 'Singapore', value: 'SGP' },
  { label: 'Sint Maarten (Dutch part)', value: 'SXM' },
  { label: 'Slovakia', value: 'SVK' },
  { label: 'Slovenia', value: 'SVN' },
  { label: 'Solomon Islands', value: 'SLB' },
  { label: 'Somalia', value: 'SOM' },
  { label: 'South Africa', value: 'ZAF' },
  { label: 'South Georgia and the South Sandwich Islands', value: 'SGS' },
  { label: 'South Sudan', value: 'SSD' },
  { label: 'Spain', value: 'ESP' },
  { label: 'Sri Lanka', value: 'LKA' },
  { label: 'Sudan', value: 'SDN' },
  { label: 'Suriname', value: 'SUR' },
  { label: 'Svalbard and Jan Mayen', value: 'SJM' },
  { label: 'Sweden', value: 'SWE' },
  { label: 'Switzerland', value: 'CHE' },
  { label: 'Syrian Arab Republic', value: 'SYR' },
  { label: 'Taiwan, Province of China', value: 'TWN' },
  { label: 'Tajikistan', value: 'TJK' },
  { label: 'Tanzania, United Republic of', value: 'TZA' },
  { label: 'Thailand', value: 'THA' },
  { label: 'Timor-Leste', value: 'TLS' },
  { label: 'Togo', value: 'TGO' },
  { label: 'Tokelau', value: 'TKL' },
  { label: 'Tonga', value: 'TON' },
  { label: 'Trinidad and Tobago', value: 'TTO' },
  { label: 'Tunisia', value: 'TUN' },
  { label: 'Turkey', value: 'TUR' },
  { label: 'Turkmenistan', value: 'TKM' },
  { label: 'Turks and Caicos Islands', value: 'TCA' },
  { label: 'Tuvalu', value: 'TUV' },
  { label: 'Uganda', value: 'UGA' },
  { label: 'Ukraine', value: 'UKR' },
  { label: 'United Arab Emirates', value: 'ARE' },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GBR',
  },
  { label: 'United States of America', value: 'USA' },
  { label: 'United States Minor Outlying Islands', value: 'UMI' },
  { label: 'Uruguay', value: 'URY' },
  { label: 'Uzbekistan', value: 'UZB' },
  { label: 'Vanuatu', value: 'VUT' },
  { label: 'Venezuela (Bolivarian Republic of)', value: 'VEN' },
  { label: 'Viet Nam', value: 'VNM' },
  { label: 'Virgin Islands (British)', value: 'VGB' },
  { label: 'Virgin Islands (U.S.)', value: 'VIR' },
  { label: 'Wallis and Futuna', value: 'WLF' },
  { label: 'Western Sahara', value: 'ESH' },
  { label: 'Yemen', value: 'YEM' },
  { label: 'Zambia', value: 'ZMB' },
  { label: 'Zimbabwe', value: 'ZWE' },
];

export const ABN_TYPE_CODES = [
  { label: 'Company', value: '051' },
  { label: 'Partnership', value: '161' },
  { label: 'Trust', value: '212' },
];

export const ABN_ORGANIZATION_TYPE_CODES = [
  { label: 'Australian Private Company', value: '027' },
  { label: 'Australian Private Company - Commonwealth', value: '028' },
  { label: 'Australian Private Company - Local', value: '029' },
  { label: 'Australian Private Company - State', value: '030' },
  { label: 'Australian Private Company - Territory', value: '031' },
  { label: 'Australian Public Company', value: '032' },
  { label: 'Australian Public Company - Commonwealth', value: '033' },
  { label: 'Australian Public Company - Local', value: '034' },
  { label: 'Australian Public Company - State', value: '035' },
  { label: 'Australian Public Company - Territory', value: '036' },
  { label: 'Cash Management Trust', value: '039' },
  { label: 'Cash Management Trust - Commonwealth', value: '040' },
  { label: 'Cash Management Trust - Local', value: '041' },
  { label: 'Cash Management Trust - State', value: '042' },
  { label: 'Cash Management Trust - Territory', value: '043' },
  { label: 'Co Operative', value: '045' },
  { label: 'Co Operative - Commonwealth', value: '046' },
  { label: 'Co Operative - Local', value: '047' },
  { label: 'Co Operative - State', value: '048' },
  { label: 'Co Operative - Territory', value: '049' },
  { label: 'Corporate Unit Trust', value: '054' },
  { label: 'Corporate Unit Trust - Commonwealth', value: '055' },
  { label: 'Corporate Unit Trust - Local', value: '056' },
  { label: 'Corporate Unit Trust - State', value: '057' },
  { label: 'Corporate Unit Trust - Territory', value: '058' },
  { label: 'Deceased Estate', value: '059' },
  { label: 'Diplomatic/ Consulate Body or High Commission', value: '064' },
  { label: 'Discretionary Trust Invest - Commonwealth', value: '067' },
  { label: 'Discretionary Trust Invest - State', value: '068' },
  { label: 'Discretionary Trust Invest - Territory', value: '069' },
  { label: 'Discretionary Trust Invest - Local', value: '070' },
  { label: 'Discretionary Trust Investment', value: '071' },
  { label: 'Discretionary Trust Services Management', value: '072' },
  { label: 'Discretionary Trust ServMgt - Commonwealth', value: '073' },
  { label: 'Discretionary Trust ServMgt - Local', value: '074' },
  { label: 'Discretionary Trust ServMgt - State', value: '075' },
  { label: 'Discretionary Trust ServMgt - Territory', value: '076' },
  { label: 'Discretionary Trust Trading', value: '077' },
  { label: 'Discretionary Trust Trading - Commonwealth', value: '078' },
  { label: 'Discretionary Trust Trading - Local', value: '079' },
  { label: 'Discretionary Trust Trading - State', value: '080' },
  { label: 'Discretionary Trust Trading - Territory', value: '081' },
  { label: 'Family Partnership', value: '087' },
  { label: 'First Home Save Accounts Trust', value: '088' },
  { label: 'Fixed Unit Trust', value: '089' },
  { label: 'Fixed Trust', value: '090' },
  { label: 'Fixed Trust - Commonwealth', value: '091' },
  { label: 'Fixed Trust - Local', value: '092' },
  { label: 'Fixed Trust - Territory', value: '093' },
  { label: 'Fixed Trust - State', value: '094' },
  { label: 'Fixed Unit Trust - Commonwealth', value: '095' },
  { label: 'Fixed Unit Trust - Local', value: '096' },
  { label: 'Fixed Unit Trust - State', value: '097' },
  { label: 'Fixed Unit Trust - Territory', value: '098' },
  { label: 'Government Entity - Commonwealth', value: '104' },
  { label: 'Government Entity - Local', value: '105' },
  { label: 'Government Entity - State', value: '106' },
  { label: 'Government Entity - Territory', value: '107' },
  { label: 'Government Statutory Authority - Commonwealth', value: '108' },
  { label: 'Government Statutory Authority - Local', value: '109' },
  { label: 'Government Statutory Authority - Territory', value: '110' },
  { label: 'Government Statutory Authority - State', value: '111' },
  { label: 'Hybrid Trust', value: '112' },
  { label: 'Hybrid Trust - Commonwealth', value: '113' },
  { label: 'Hybrid Trust - Local', value: '114' },
  { label: 'Hybrid Trust - State', value: '115' },
  { label: 'Hybrid Trust - Territory', value: '116' },
  { label: 'Limited Partnership', value: '120' },
  { label: 'Limited Partnership - Commonwealth', value: '121' },
  { label: 'Limited Partnership - Local', value: '122' },
  { label: 'Limited Partnership - State', value: '123' },
  { label: 'Limited Partnership - Territory', value: '124' },
  { label: 'Other Incorporated Entity', value: '145' },
  { label: 'Other Incorporated Entity - Commonwealth', value: '146' },
  { label: 'Other Incorporated Entity - Local', value: '147' },
  { label: 'Other Incorporated Entity - State', value: '148' },
  { label: 'Other Incorporated Entity - Territory', value: '149' },
  { label: 'Other Partnership', value: '150' },
  { label: 'Other Unincorporated Entity', value: '156' },
  { label: 'Other Unincorporated Entity - Commonwealth', value: '157' },
  { label: 'Other Unincorporated Entity - Local', value: '158' },
  { label: 'Other Unincorporated Entity - State', value: '159' },
  { label: 'Other Unincorporated Entity - Territory', value: '160' },
  { label: 'Pooled Superannuation Trust', value: '168' },
  { label: 'Public Trading Trust', value: '174' },
  { label: 'Public Trading Trust - Commonwealth', value: '175' },
  { label: 'Public Trading Trust - Local', value: '176' },
  { label: 'Public Trading Trust - State', value: '177' },
  { label: 'Public Trading Trust - Territory', value: '178' },
  { label: 'Public Unit Trust Listed', value: '179' },
  { label: 'Public Unit Trust Listed - Commonwealth', value: '180' },
  { label: 'Public Unit Trust Listed - Local', value: '181' },
  { label: 'Public Unit Trust Listed - Territory', value: '182' },
  { label: 'Public Unit Trust Listed - State', value: '183' },
  { label: 'Public Unit Trust Unlisted', value: '184' },
  { label: 'Public Unit Trust Unlisted - Commonwealth', value: '185' },
  { label: 'Public Unit Trust Unlisted - Local', value: '186' },
  { label: 'Public Unit Trust Unlisted - State', value: '187' },
  { label: 'Public Unit Trust Unlisted - Territory', value: '188' },
  { label: 'Strata Title', value: '205' },
  { label: 'Strata Title - Commonwealth', value: '206' },
  { label: 'Strata Title - Local', value: '207' },
  { label: 'Strata Title - State', value: '208' },
  { label: 'Strata Title - Territory', value: '209' },
  { label: 'Partnership - Commonwealth', value: '223' },
  { label: 'Partnership - Local', value: '225' },
  { label: 'Partnership - State', value: '228' },
  { label: 'Partnership - Territory', value: '231' },
];

export const BANK_CODE_IDENTIFIERS = [
  { value: 'SWIFT', label: 'SWIFT-BIC' },
  { value: 'SORT_CODE', label: 'Sort Code' },
  { value: 'ABA_ROUTING', label: 'ABA Routing' },
];

export const DOCUMENT_TYPES_TO_ATTACHED_IN_NOTIFICATIONS = [
  { value: 'PERSONAL_DETAILS_CSV', label: 'Personal Information' },
  { value: 'DRIVERS_LICENCE', label: 'Drivers Licence' },
  { value: 'PASSPORT', label: 'Passport' },
  { value: 'CONTRACT', label: 'Contract' },
  { value: 'CV', label: 'Resume / CV' },
  { value: 'NDA', label: 'Non Disclosure Agreement(NDA)' },
  { value: 'INSURANCE', label: 'Insurance' },
  { value: 'JBR', label: 'Joint Business Relationship(JBR)' },
  { value: 'TRAINING_CERTIFICATES', label: 'Training Certificates' },
  { value: 'INSURANCE_LIABILITY', label: 'Liability Insurance' },
  { value: 'INSURANCE_INDEMNITY', label: 'Indemnity Insurance' },
  { value: 'INSURANCE_WORKSAFE', label: 'Worksafe Insurance' },
  { value: 'WORKING_WITH_CHILDREN', label: 'Working with children' },
  { value: 'BANK_LETTER', label: 'Bank Letter' },
  { value: 'MSA', label: 'Master Service Agreement(MSA)' },
  { value: 'CONTRACTOR_MANUAL', label: 'Contractor Manual' },
  { value: 'POLICE_CHECK', label: 'Police Check' },
  { value: 'SOW', label: 'Statement Of Work' },
  { value: 'SKILL_CERTIFICATES', label: 'Skill Certificates' },
  {
    value: 'ISO9001_AND_NDIS_CERTIFICATES_SKILL_CERTIFICATES',
    label: 'ISO and NDIS Certificates',
  },
];

export const TASK_TYPES = {
  ABN: 'ABN',
  CONTRACTOR_SETUP: 'CONTRACTOR_SETUP',
  DOCUMENT_VACCINATION_INFO: 'DOCUMENT_VACCINATION_INFO',
  WORK_RIGHTS_CHECK: 'WORK_RIGHTS_CHECK',
  DOCUMENT_UPLOAD_INSURANCE_WORKSAFE: 'DOCUMENT_UPLOAD_INSURANCE_WORKSAFE',
  DOCUMENT_UPLOAD_ISO9001_CERTIFICATES: 'DOCUMENT_UPLOAD_ISO9001_CERTIFICATES',
  DOCUMENT_UPLOAD_NDIS_CERTIFICATES: 'DOCUMENT_UPLOAD_NDIS_CERTIFICATES',
  DOCUMENT_UPLOAD_POLICE_CHECK: 'DOCUMENT_UPLOAD_POLICE_CHECK',
  DOCUMENT_UPLOAD_POLICE_CHECK_NDIS: 'DOCUMENT_UPLOAD_POLICE_CHECK',
  DOCUMENT_UPLOAD_SKILL_CERTIFICATES: 'DOCUMENT_UPLOAD_SKILL_CERTIFICATES',
  DOCUMENT_UPLOAD_TRAINING_CERTIFICATES:
    'DOCUMENT_UPLOAD_TRAINING_CERTIFICATES',
  DOCUMENT_UPLOAD_WORK_RIGHTS_CHECK: 'DOCUMENT_UPLOAD_WORK_RIGHTS_CHECK',
  DOCUMENT_UPLOAD_WORKER_COMPENSATION_CERT:
    'DOCUMENT_UPLOAD_WORKER_COMPENSATION_CERT',
  DOCUMENT_UPLOAD_WORKING_WITH_CHILDREN:
    'DOCUMENT_UPLOAD_WORKING_WITH_CHILDREN',
  DOCUMENT_UPLOAD_WORKING_WITH_CHILDREN_NDIS:
    'DOCUMENT_UPLOAD_WORKING_WITH_CHILDREN_NDIS',
  IDENTITY_CHECK: 'IDENTITY_CHECK',
  PERSONAL_INFO: 'PERSONAL_INFO',
  QUALIFICATIONS_EDUCATIONAL: 'QUALIFICATIONS_EDUCATIONAL',
  SUPER_CHOICE: 'SUPER_CHOICE',
  TFN_DECLARATION: 'TFN_DECLARATION',
  WORKER_CONTRACT_INFO: 'WORKER_CONTRACT_INFO',
  WORKER_CONTRACTOR_RATE: 'WORKER_CONTRACTOR_RATE',
  WORKER_CONTRACTOR_TOOL: 'WORKER_CONTRACTOR_TOOL',
  WORKER_ENGAGEMENT_INFO: 'WORKER_ENGAGEMENT_INFO',
  WORKER_INFO: 'WORKER_INFO',
  WORKER_RATE_DETAILS: 'WORKER_RATE_DETAILS',
  WORKER_TIMESHEET: 'WORKER_TIMESHEET',
  WORKER_WORKORDER: 'WORKER_WORKORDER',
  WORKING_WITH_CHILDREN: 'WORKING_WITH_CHILDREN',
  WORKSITE_INFO: 'WORKSITE_INFO',
  BANK_DETAILS: 'BANK_DETAILS',
  AHPRA_INFORMATION: 'AHPRA_INFORMATION',
  DOCUMENT_UPLOAD_PHARMACY_STUDENT: 'DOCUMENT_UPLOAD_PHARMACY_STUDENT',
};

export const DOCUMENT_TYPES = {
  WORK_RIGHTS_CHECK: 'WORK_RIGHTS_CHECK',
};

export const CONSORTIO_CONTRACTOR_DECISION_TOOL_URL =
  'http://form.jotform.co/form/52137756567869';

// Inline with date-fns v1.30.1 format tokens
export const ISO_DATE_FORMAT = 'yyyy-MM-dd';
export const AU_NUMERIC_DATE_FORMAT = 'dd/MM/yyyy';
export const DOB_FORMAT = 'd MMMM yyyy';
export const CONTRACT_FORMAT = 'd MMM yyyy';
export const FULL_DATE_FORMAT = 'd MMMM yyyy, h:mm a';

export const EXPIRY_REMINDER_PRIOR_IN_DAYS = 14;

export const POSTCODE_REGEX = /^[0-9]{4}$/;

export const TFN_EXEMPTION_CODES = [
  { value: 'NONE', label: 'Yes, I do' },
  {
    value: '1',
    label:
      'I have made a separate application/enquiry to the ATO for a new or existing TFN.',
  },
  {
    value: '3',
    label:
      'I am claiming an exemption because I am under 18 years of age and do not earn enough to pay tax.',
  },
  {
    value: '4',
    label:
      'I am claiming an exemption because I am in receipt of a pension, benefit or allowance.',
  },
  {
    value: '0',
    label: 'I choose not to disclose.',
  },
];

export const COVID_INFO = {
  COVID_VACCINATION_STATUS: 'COVID_VACCINATION_STATUS',
  COVID_LAST_INJECTION_DATE: 'COVID_LAST_INJECTION_DATE',
  COVID_AKNOWLEDGEMENT: 'COVID_AKNOWLEDGEMENT',
};

export const VACCINATION_STATUS = {
  FIRST_VACCINATION: 'First Vaccination',
  SECOND_VACCINATION: 'Second Vaccination',
  BOOSTER: 'Booster',
  MEDICAL_EXEMPTION: 'Medical Exemption',
};

//actions dropdown menu
export const SET_TO_ACTIVE = 'Set to Active';
export const EXTEND_CONTRACT = 'Extend Contract';
export const AMEND_CONTRACT = 'Amend Contract';
export const DELETE_CONTRACT = 'Delete Contract';
export const CANCEL_CONTRACT = 'Cancel Contract';
export const ARCHIVE_CONTRACT = 'Archive Contract';
export const NO_CONTRACT_ACTION = 'No Available Contract Action';

export const SIGNED = 'signed';
export const ORIGINAL = 'original';
export const USER = 'user';

export const DOCX =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const CONTRACT_TYPES = {
  INITIAL: 'INITIAL',
  EXTENSION: 'EXTENSION',
  AMENDMENT: 'AMENDMENT',
};

export const BANNER_TEXT = {
  AMENDMENT: 'Contract amendment from',
  EXTENSION: 'Contract extension from',
};

export const getContractType = (
  isAmendment: boolean,
  isExtension: boolean
): TimesheetRequestModelContractType => {
  return isAmendment
    ? TimesheetRequestModelContractType.AMENDMENT
    : isExtension
    ? TimesheetRequestModelContractType.EXTENSION
    : TimesheetRequestModelContractType.INITIAL;
};

export const CONTRACT_OPERATION = {
  VIEW_CONTRACT: 'View Contract',
  VIEW_WORKER: 'View Worker',
  CONTRACT_EXTENSION: 'Contract Extension',
  CONTRACT_AMENDMENT: 'Contract Amendment',
  NEW_CONTRACT: 'New Contract',
  NEW_WORKER: 'New Worker',
};

export const ARCHIVED_REASON = {
  CONTRACT_ARCHIVED: 'CONTRACT_ARCHIVED',
  CONTRACT_CANCELLED: 'CONTRACT_CANCELLED',
  CONTRACT_AMENDED: 'CONTRACT_AMENDED',
  CONTRACT_EXPIRED: 'CONTRACT_EXPIRED',
  EARLY_OFF_BOARDED: 'EARLY_OFF_BOARDED',
  CONTRACT_EXTENDED: 'CONTRACT_EXTENDED',
};

export const CONTRACT_CLASSIFICATION_LEVEL = [
  { value: 'Pharmacist Manager', label: 'Pharmacist Manager' },
  { value: 'Pharmacist in Charge', label: 'Pharmacist in Charge' },
  { value: 'Experienced Pharmacist', label: 'Experienced Pharmacist' },
  { value: 'Pharmacist', label: 'Pharmacist' },
  {
    value: 'Pharmacy Intern – 1st half of training',
    label: 'Pharmacy Intern – 1st half of training',
  },
  {
    value: 'Pharmacy Intern  – 2nd half of training',
    label: 'Pharmacy Intern  – 2nd half of training',
  },
  {
    value: 'Pharmacy Student –1st year of course',
    label: 'Pharmacy Student –1st year of course',
  },
  {
    value: 'Pharmacy Student –2nd year of course',
    label: 'Pharmacy Student –2nd year of course',
  },
  {
    value: 'Pharmacy Student –3rd year of course',
    label: 'Pharmacy Student –3rd year of course',
  },
  {
    value: 'Pharmacy Student –4th year of course',
    label: 'Pharmacy Student –4th year of course',
  },
  {
    value: 'Pharmacy assistant – level 1',
    label: 'Pharmacy assistant – level 1',
  },
  {
    value: 'Pharmacy assistant – level 2',
    label: 'Pharmacy assistant – level 2',
  },
  {
    value: 'Pharmacy assistant – level 3',
    label: 'Pharmacy assistant – level 3',
  },
  {
    value: 'Pharmacy assistant – level 4',
    label: 'Pharmacy assistant – level 4',
  },
  { value: 'Nurse', label: 'Nurse' },
  { value: 'Junior – Under 16', label: 'Junior – Under 16' },
  { value: 'Junior – 16 years of age', label: 'Junior – 16 years of age' },
  { value: 'Junior – 17 years of age', label: 'Junior – 17 years of age' },
  { value: 'Junior – 18 years of age', label: 'Junior – 18 years of age' },
  { value: 'Junior – 19 years of age', label: 'Junior – 19 years of age' },
  { value: 'Junior – 20 years of age', label: 'Junior – 20 years of age' },
  { value: 'N/A –  NSO staff', label: 'N/A –  NSO staff' },
  { value: 'N/A – Contractor', label: 'N/A – Contractor' },
  { value: 'Retail Employee level 1', label: 'Retail Employee level 1' },
  { value: 'Retail Employee level 2', label: 'Retail Employee level 2' },
  { value: 'Retail Employee level 3', label: 'Retail Employee level 3' },
  { value: 'Retail Employee level 4', label: 'Retail Employee level 4' },
  { value: 'Retail Employee level 5', label: 'Retail Employee level 5' },
  { value: 'Retail Employee level 6', label: 'Retail Employee level 6' },
  { value: 'Retail Employee level 7', label: 'Retail Employee level 7' },
  { value: 'Retail Employee level 8', label: 'Retail Employee level 8' },
];

export const CONTRACT_MODERN_AWARD = [
  {
    value: 'Pharmacy Industry Award 2020',
    label: 'Pharmacy Industry Award 2020',
  },
  {
    value: 'General Retail Industry Award 2020',
    label: 'General Retail Industry Award 2020',
  },
  { value: 'Award Free', label: 'Award Free' },
];

export const CONTRACT_REPORTING_LINE = [
  { value: 'Pharmacist Manager', label: 'Pharmacist Manager' },
  { value: 'Retail Manager', label: 'Retail Manager' },
  {
    value: 'Business Development Manager',
    label: 'Business Development Manager',
  },
  {
    value: 'Regional Pharmacist Manager',
    label: 'Regional Pharmacist Manager',
  },
  { value: 'Other', label: 'Other' },
];

export const CONTRACT_RATE_OF_PAY = [
  { value: 'Award Rate', label: 'Award Rate' },
  { value: 'Above Award Rate', label: 'Above Award Rate' },
  { value: 'Fixed Rate', label: 'Fixed Rate' },
  { value: 'Salary', label: 'Salary' },
];

export const SELECTED_CONTRACT_RATE_OF_PAY = [
  { value: 'Fixed Rate', label: 'Fixed Rate' },
  { value: 'Salary', label: 'Salary' },
];

export const CONTRACT_POSITION_TITLE = [
  { value: 'Pharmacist Manager', label: 'Pharmacist Manager' },
  { value: 'Pharmacist In Charge', label: 'Pharmacist In Charge' },
  { value: 'Pharmacist', label: 'Pharmacist' },
  { value: 'Pharmacy Assistant', label: 'Pharmacy Assistant' },
  { value: 'Pharmacy Intern', label: 'Pharmacy Intern' },
  { value: 'Pharmacy Student', label: 'Pharmacy Student' },
  { value: 'Retail Manager', label: 'Retail Manager' },
  {
    value: 'Assistant Retail Manager/ Stock Taker/ Specialist Area Coordinator',
    label: 'Assistant Retail Manager/ Stock Taker/ Specialist Area Coordinator',
  },
  { value: 'Dispense Tech', label: 'Dispense Tech' },
  { value: 'Webster Packer', label: 'Webster Packer' },
  { value: 'Beauty Advisor', label: 'Beauty Advisor' },
  { value: 'Sleep Apnoea Consultant', label: 'Sleep Apnoea Consultant' },
  { value: 'Nurse', label: 'Nurse' },
  { value: 'Office Staff', label: 'Office Staff' },
  { value: 'Sales Assistant', label: 'Sales Assistant' },
  { value: 'Stock Controller', label: 'Stock Controller' },
];

export const CONTRACT_SPECIAL_CONDTIONS = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];

export const CONTRACT_INDIVIDUAL_FLEXIBILITY_AGREEMENT = [
  { value: 'Yes', label: 'Yes' },
  { value: 'No', label: 'No' },
];
