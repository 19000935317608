import { Auth } from 'aws-amplify';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { Role } from 'types/user';
import { TOKEN_DISABLED } from 'utils/configs';
import HttpClient from 'utils/HttpClient';

const useUserRoles = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(TOKEN_DISABLED);
  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const getUserIpAddress = async () => {
    const ipRes = await axios.get(
      `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.REACT_APP_ABSTRACT_API_KEY}`
    );
    const { ip_address } = ipRes.data;
    return ip_address;
  };

  useEffect(() => {
    if (!isAuthenticated) {
      (async () => {
        try {
          const data = await Auth.currentSession();
          console.log('Auth.currentSession()', data);
          const token = data.getIdToken();
          localStorage.setItem(
            'channelId',
            JSON.stringify(token.payload['custom:channel'])
          );
          const userIp = await getUserIpAddress();
          localStorage.setItem('userIp', userIp);
          setIsAuthenticated(true);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const getUserRoles = async () => {
      try {
        const res = await HttpClient.get('/user/details');
        setUserRoles(res.data.roles);
      } catch (error) {
        console.log(error);
      }
    };

    if (isAuthenticated) {
      getUserRoles();
    }
  }, [isAuthenticated]);

  return userRoles;
};

export default useUserRoles;
